
body {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  background: linear-gradient(to bottom right, #6db0ac, #8ce1dc);
}

/* Header styles */
.logo-btn-image{
display: flex;
flex-direction: row;
justify-content:center;
align-items: center;
}
.logo-btn-image img{
  margin-bottom: 0px;
}
.logo-btn-image a{
  margin-bottom: -4px;
}
header {
  background-color: #f8f8f8;
  padding: 20px;
  text-align: center;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
}

h1 {
  color: #333;
  font-size: 36px;
  margin: 0;
}

.header-image {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1px;
}

.header-image img {
  max-width: 100%;
  max-height: 591px;
  object-fit: contain;
  border-radius: 10px;
}
/* Main section styles */
.main-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin: 20px;
}

.left-section {
  flex: 4;
  padding: 20px;
  background-color: #f8f8f8;
  border-radius: 10px;
  margin-right: 20px;
  margin-left:25px;
  min-height: 500px;
}
.in-container{
  display: flex;
  justify-content: left;
  align-items: flex-start;
  margin: 20px;
  text-align: left;
}
.left-in{
  width: 50%;
}

.image-container {
  display: flex;
}

.image-item {
  flex-basis: 90%;
  text-align: center;
  margin: 18px;
}

.image-item img {
  width: 100%;
  max-width: 500px;
}
.image-item-left img {
  width: 100%;
  max-width: 500px;
}

.image-item p {
  margin-top: 5px;
}


.image-item-small-1 {
  flex: 1;
  text-align: center;
  margin: 10px;
}

.image-item-small-1 img {
  width: 100%;
  max-width: 500px;
}

.image-item-small-1 p {
  margin-top: 5px;
}

.image-item-small {
  flex: 1;
  text-align: center;
  margin: 10px;
}

.image-item-small img {
  width: 100%;
  max-width: 200px;
}

.image-item-small p {
  margin-top: 5px;
}
.right-in{
  width: 50%;
  padding-left: 60px;
}
.linkki{
  color: #000;
  font-size: 18pt;
  text-decoration: none;
}

.right-section {
  flex: 1;
  padding: 20px;
  background-color: #f8f8f8;
  border-radius: 10px;
  min-height: 444px;
}

/* Button link */
.button-link {
  display: inline-block;
  padding: 10px 20px;
  background-color: #f5f5f5;
  color: #333;
  text-decoration: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-weight: bold;
}

.button-link:hover {
  background-color: #e0e0e0;
}

/* Main styles */
main {
  max-width: 100%;
  margin: 0 auto;
  padding: 20px;
  padding-left: 55px;
  padding-right: 80px;
}

section {
  margin-bottom: 40px;
}

h2 {
  color: #333;
  font-size: 24px;
  margin-bottom: 10px;
}

p {
  color: #666;
  line-height: 1.5;
}

/* Bottom section styles */
.bottom-section {
  text-align: center;
  margin: 40px 0;
  margin-top: 0px;
  max-width: 100%;
  padding-left: 30px;
  padding-right: 30px;
}

.bottom-section h2 {
  color: #333;
  font-size: 24px;
  margin-bottom: 10px;
}

.bottom-section p {
  color: #666;
  line-height: 1.5;
}

.bottom-box{
  flex: 1;
  padding: 20px;
  background-color: #f8f8f8;
  border-radius: 10px;
  margin-right: 60px;
  margin-left:70px;
}
footer{
  text-align: right;
  padding-right: 20pt;
}
footer p{
  color: #5d232e;
}

/* Info gallery styles */
.info-gallery {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin: 60px;
  margin-top: 0px;
}

.info-card {
  flex: 1;
  padding: 10px;
  border-radius: 10px;
  background-color: #fff;
  text-align: center;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
  min-height: 630px;
  margin-right: 10px;
  margin-left:8px;
}

.info-card:hover {
  transform: translateY(-5px);
}


.info-card h3 {
  margin-top: 10px;
  font-size: 20px;
}

.info-card p {
  margin-top: 10px;
}


.logo-image img {
  max-width: 100%;
  object-fit: contain;
  border-radius: 10px;
}
.kartta{
  width: 100%;
  height: 300px;
  border: none;
}

.kalenteri{
  width: 100%;
  height: 300px;
  border: none;
}

.breadcrumbs {
  margin-bottom: 10px;
}

.separator {
  margin: 0 5px;
}

.current-page {
  font-weight: bold;
}

.image-text-container {
  display: flex;
} 
.image-item-small {

  margin-right: 20px;
}

.text-item {
  flex-basis: auto;
  text-align: left;
}
.text-item-left {

  margin-right: 30px;
  text-align: left;
}
.inner-left{
  flex-basis: 40%;
}
.inner-right{
  flex-basis: 60%;
}

.image-item-left{
  margin-right: 20px;
}
.mb-0{
  margin-bottom: 0px;
}


/* Styles for mobile devices */
@media (max-width: 767px) 

{

  main {
    max-width: 100%;
    margin: 0 auto;
    padding: 20px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .main-container, .info-gallery{
    flex-wrap: wrap;
    margin:0px;
  }
  .main-container{
    margin: 0px;
  }
  .left-section, .right-section{
    flex: 100%;
    margin-left:0px;
    margin-right: 0px;
    margin-bottom: 10px;
}
.info-card {
  flex: 100%;
  margin-left:20px;
  margin-right: 20px;
  margin-bottom: 10px;
}

.kartta{
  width: 100%;
  height: 300px;
  border: none;
}
.in-container{
  flex-direction: column;
}

.left-in{
  flex-basis: auto;
  width: 100%;
}
.right-in{
  flex-basis: auto;
  width: 100%;
  padding-left: 0px;
}

.image-container{
  display: flex;
  flex-direction: column;
}
.image-text-container {
  display: flex;
  flex-direction: column;
}

.image-item, .text-item {
  width: 100%;
  margin: 0;
}

.bottom-box{
  margin-right: 20px;
  margin-left: 20px;
}


.bottom-section {
  text-align: center;
  margin: 40px 0;
  margin-top: 0px;
  max-width: 100%;
  padding-left: 0px;
  padding-right: 0px;
}

.logo-btn-image img {
  margin-bottom: 0px;
  width: 150px;
}

}

/* Styles for tablets */
@media (min-width: 768px) and (max-width: 1023px) {
  /* Your CSS code for tablets */
}
